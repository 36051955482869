<template lang="">
  <div class="min-h-screen">
    <Navbar />
    <div class="h-18"></div>
    <div id="imprint-content" class="max-w-screen-lg mx-auto px-2 py-4">
      <h1 class="mt-16">Impressum</h1>
      <p>Praxis Linda Dendtel</p>
      <p>
        Linda Dendtel<br />Merseburger Straße 62<br />04177 Leipzig<br />praxis.dendtel@gmail.com
      </p>
      <h3>Haftungsausschluss</h3>
      <p>
        Die bereitgestellten Informationen auf dieser Website habe wir mit
        großer Sorgfalt geprüft. Dies ist trotzdem keine Garantie dafür, dass
        alle Angaben stets vollständig, korrekt und aktuell sind, da sich im
        Netz vieles schnell ändert. Dies gilt vor allem für alle Links zu
        anderen Websites, auf die direkt oder indirekt verwiesen wird. Wir haben
        keinen Einfluss auf die Inhalte anderer Internetangebote.
      </p>
      <h3>Bildquellen</h3>
      <p>
        Fast alle Bilder, die für die Seite verwendet wurden sind von
        Unsplash.com und damit frei verwendbar. Sie sind hier mit einem Link
        angegeben. Zwei Bilder sind mit erworbener Lizenz von Depositphotos, die
        werden ebenfalls mit URL angegeben.
      </p>
      <h4>Startseite</h4>
      <p>
        <a href="https://unsplash.com/photos/WUydTfaP3t4"
          >https://unsplash.com/photos/WUydTfaP3t4</a
        >
      </p>
      <h5>Lizenzen für Fotos vorhanden von depositphotos.com:</h5>
      <p>
        <a
          href="https://de.depositphotos.com/53729691/stock-photo-smile-free-and-happy-woman.html"
          >https://de.depositphotos.com/53729691/stock-photo-smile-free-and-happy-woman.html</a
        >
      </p>
      <p>
        <a
          href="https://de.depositphotos.com/440978322/stock-photo-lonely-young-asian-woman-sitting.html"
          >https://de.depositphotos.com/440978322/stock-photo-lonely-young-asian-woman-sitting.html</a
        >
      </p>
      <h4>Körperpsychotherapie</h4>
      <p>
        <a href="https://unsplash.com/photos/74TufExdP3Y"
          >https://unsplash.com/photos/74TufExdP3Y</a
        >
      </p>
      <p>
        <a href="https://unsplash.com/photos/lu15z1m_KfM"
          >https://unsplash.com/photos/lu15z1m_KfM</a
        >
      </p>
      <p>
        <a href="https://unsplash.com/photos/KHipnBn7sdY"
          >https://unsplash.com/photos/KHipnBn7sdY</a
        >
      </p>
      <p>
        <a href="https://unsplash.com/photos/xMNel_otvWs"
          >https://unsplash.com/photos/xMNel_otvWs</a
        >
      </p>
      <h4>Über mich</h4>
      <p>
        <a href="https://unsplash.com/photos/n6XTFGsQEoE"
          >https://unsplash.com/photos/n6XTFGsQEoE</a
        >
      </p>
      <h4>Kontakt</h4>
      <p>
        <a href="https://unsplash.com/photos/DsmwN6LbaK0"
          >https://unsplash.com/photos/DsmwN6LbaK0</a
        >
      </p>
    </div>
    <Footer />
  </div>
</template>
<script>
import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "Imprint",
  components: { Navbar, Footer },
  data() {
    return {};
  },
});
</script>
<style lang="scss">
#imprint-content {
  a {
    color: var(--accent-color);
  }
  p {
    padding: 1rem 0 1rem 0;
  }
  h5 {
    padding-top: 2rem;
    font-size: 1.125rem;
    font-weight: 400;
  }
  h4 {
    padding-top: 2rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: black;
  }
  h3 {
    padding-top: 2rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: black;
  }
  h1 {
    font-size: 1.75rem;
    font-weight: 600;
  }
}
@media (max-width: 380px) {
  #imprint-content {
    h1 {
      font-size: 1.125rem;
    }
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.875rem;
    }
    h5 {
      font-size: 0.875rem;
    }
    p {
      font-size: 0.75rem;
    }
    li {
      font-size: 0.75rem;
    }
  }
}
</style>

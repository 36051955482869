<template lang="">
  <div class="pt-8">
    <div id="ab-main-1" class="grid mt-16 px-4">
      <img
        id="about-pic-2"
        :src="profilePic"
        alt=""
        class="mx-auto my-auto object-cover w-full h-full"
      />
      <div id="ab-main-1-content" class="ml-4 pr-8 my-auto">
        <h3 class="text-3xl font-semibold">Über mich</h3>
        <div
          class="w-24 my-6 mt-4 mb-10"
          style="border-bottom: 2px solid var(--accent-color)"
        ></div>
        <p class="">
          Ich bin Linda, Heilpraktikerin auf dem Gebiet der Psychotherapie und
          liebe es Räume und Möglichkeiten für Menschen zu schaffen, in denen
          sie sich von Altem lösen, sich entfalten und wachsen können. Ich
          empfinde es als eine sehr wertvolle Aufgabe für Menschen in dieser Art
          und Weise dasein zu können, da ich in meinem eigenen Leben erfahren
          durfte, wie wunderbar und bereichernd dies ist.
        </p>
        <p>
          Auf dem Weg zu mir selbst, vom Kopf in den Körper, in die Balance und
          Öffnung, haben unzählige Erfahrungen, Begegnungen, Lehrer und
          Therapeuten meine Schritte begleitet und geprägt - und tun dies
          weiterhin. Ich bin heute unglaublich dankbar dafür, wieviel
          Verständnis und liebevolle Aufmerksamkeit mir dabei stets geschenkt
          wurde.
        </p>
        <p>
          In meinem Leben ist grundlegende und grundlose Freude, Kraft und
          Kreativität erblüht, aus deren Summe sich ein Lebensgefühl ergeben
          hat, welches ich zuvor niemals für möglich gehalten hätte.
        </p>
        <p>
          Diese Art von Veränderung im Bewusstsein und Körpergefühl eines
          Menschen zu unterstützen und zu begleiten, ist für mich persönlich
          eine Aufgabe, die mich mit tiefer Dankbarkeit und Ehrfurcht vor dem
          Leben erfüllt.
        </p>
        <p>Darf ich dich ein Stück deines Weges begleiten?</p>
      </div>
      <img
        id="about-pic"
        :src="profilePic"
        alt=""
        class="mx-auto my-auto object-cover w-full h-full"
      />
    </div>
    <!-- TABLE -->
    <div
      id="ab-main-2"
      class="text-white relative mt-16"
      :style="{
        'background-image': 'url(' + tree + ')',
      }"
      style="background-size: cover;"
    >
      <div class=" bg-black bg-opacity-30 py-8">
        <h2 class="max-w-screen-lg mx-auto text-3xl font-semibold mt-16 px-4">
          Lebenslauf
        </h2>
        <p class="max-w-screen-lg mx-auto font-semibold mt-2 px-4">
          Jahrgang 1984, verheiratet
        </p>
        <div id="lebenslauf-table" class="grid max-w-screen-lg mx-auto">
          <p class="year">2024</p>
          <p class="desc">
            <strong>TRE (Trauma Release Exercise)</strong>
            Provider Training bei Deborah Brown, UK
          </p>

          <p class="year">2023</p>
          <p class="desc">
            <strong>Conscious Body, Voice Dialogue</strong>
            bei Judith Hendin, USA
          </p>

          <p class="year">2022 - 2024</p>
          <p class="desc">
            <strong
              >Ausbildung zur Traumayogatherapeutin / Traumasensibles Yoga -
              TSY</strong
            >
            bei Angela Dunemann, Regina Weiser & Joachim Pfahl
          </p>

          <!-- <p class="year">2021 - 2022</p>
          <p class="desc">
            <strong>Ausbildung zur Trauerbegleiterin</strong>
            an der Isolde-Richter-Schule
          </p> -->

          <p class="year">seit 2022</p>
          <p class="desc">
            <strong>Voice Dialogue</strong>
            bei Judith Hendin, USA, u.a. Traumaarbeit mit Voice Dialogue
          </p>

          <p class="year">seit 2021</p>
          <p class="desc">
            <strong>Voice Dialogue</strong>
            bei Andrea Naurath, Berlin
          </p>

          <p class="year">2021</p>
          <p class="desc">
            <strong>Heilpraktikerin auf dem Gebiet der Psychotherapie</strong>
          </p>

          <p class="year">2020 - 2021</p>
          <p class="desc">
            <strong>Ausbildung zur Heilpraktikerin für Psychotherapie</strong>
            an der Isolde-Richter-Schule
          </p>

          <p class="year">2020 - 2022</p>
          <p class="desc">
            <strong>Vertiefung im Therapeutentraining</strong>
            der energetischen Körperpsychotherapie am Institut für
            Beziehungsdynamik, Berlin
          </p>

          <p class="year">2019 - 2020</p>
          <p class="desc">
            <strong>Ausbildung</strong>
            am Institut für Beziehungsdynamik
            <strong>zur energetischen Körperpsychotherapeutin</strong>, Berlin
          </p>

          <p class="year">2018 - 2021</p>
          <p class="desc">
            <strong>Studium der Psychologie</strong> an der FernUni Hagen
          </p>
          <p class="year">2010 - 2021</p>
          <p class="desc">freie Texterin</p>
          <p class="year last">2003 - 2015</p>
          <p class="desc last">
            Magister der Philosophie und Neueren deutschen Literaturwissenschaft
            an der Freien Universität Berlin
          </p>
        </div>
      </div>
    </div>
    <!-- TABLE MOBILE -->
    <div
      id="ab-main-2-mobile"
      class="text-white relative mt-16"
      :style="{
        'background-image': 'url(' + treeM + ')',
      }"
      style="background-size: cover;"
    >
      <div class=" bg-black bg-opacity-30 py-8">
        <h2 class="max-w-screen-lg mx-auto text-3xl font-semibold mt-16 px-4">
          Lebenslauf
        </h2>
        <div id="lebenslauf-table" class="grid max-w-screen-lg mx-auto">
          <p class="year">2024</p>
          <p class="desc">
            <strong>TRE (Trauma Release Exercise)</strong>
            Provider Training bei Deborah Brown, UK
          </p>

          <p class="year">2023</p>
          <p class="desc">
            <strong>Conscious Body, Voice Dialogue</strong>
            bei Judith Hendin, USA
          </p>

          <p class="year">2022 - 2024</p>
          <p class="desc">
            <strong
              >Ausbildung zur Traumayogatherapeutin / Traumasensibles Yoga -
              TSY</strong
            >
            bei Angela Dunemann, Regina Weiser & Joachim Pfahl
          </p>

          <!-- <p class="year">2021 - 2022</p>
          <p class="desc">
            <strong>Ausbildung zur Trauerbegleiterin</strong>
            an der Isolde-Richter-Schule
          </p> -->

          <p class="year">seit 2022</p>
          <p class="desc">
            <strong>Voice Dialogue</strong>
            bei Judith Hendin, USA, u.a. Traumaarbeit mit Voice Dialogue
          </p>

          <p class="year">seit 2021</p>
          <p class="desc">
            <strong>Voice Dialogue</strong>
            bei Andrea Naurath, Berlin
          </p>

          <p class="year">2021</p>
          <p class="desc">
            <strong>Heilpraktikerin auf dem Gebiet der Psychotherapie</strong>
          </p>

          <p class="year">2020 - 2021</p>
          <p class="desc">
            <strong>Ausbildung zur Heilpraktikerin für Psychotherapie</strong>
            an der Isolde-Richter-Schule
          </p>

          <p class="year">2020 - 2022</p>
          <p class="desc">
            <strong>Vertiefung im Therapeutentraining</strong>
            der energetischen Körperpsychotherapie am Institut für
            Beziehungsdynamik, Berlin
          </p>

          <p class="year">2019 - 2020</p>
          <p class="desc">
            <strong>Ausbildung</strong>
            am Institut für Beziehungsdynamik
            <strong>zur energetischen Körperpsychotherapeutin</strong>, Berlin
          </p>

          <p class="year">2018 - 2021</p>
          <p class="desc">
            <strong>Studium der Psychologie</strong> an der FernUni Hagen
          </p>
          <p class="year">2010 - 2021</p>
          <p class="desc">freie Texterin</p>
          <p class="year last">2003 - 2015</p>
          <p class="desc last">
            Magister der Philosophie und Neueren deutschen Literaturwissenschaft
            an der Freien Universität Berlin
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import treeM from "@/assets/pictures/path_12_mobile.jpg";
import profilePic from "@/assets/pictures/profile_pic.jpg";
import tree from "@/assets/pictures/path_12.jpg";
export default {
  data() {
    return {
      profilePic,
      tree,
      treeM,
    };
  },
};
</script>
<style lang="scss">
#about-pic {
  max-height: 700px;
  max-width: 480px;
}
#about-pic-2 {
  display: none;
}
#ab-main-1 {
  grid-template-columns: 2fr 1fr;
  #ab-main-1-content {
    padding-top: 2rem;
    p {
      padding: 1rem 0 1rem 0;
    }
  }
}
#ab-main-2-mobile {
  display: none;
  p {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  }
}
#lebenslauf-table {
  grid-template-columns: 1fr 4fr;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 1rem 0 1rem;
  p {
    border-top: 1px solid white;
    padding: 0.5rem;
  }
  .last {
    border-bottom: 1px solid white;
  }
}
@media (max-width: 1300px) {
  #ab-main-1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1050px) {
  #ab-main-2-mobile {
    display: block;
    p {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    }
  }
  #ab-main-2 {
    display: none;
  }
  #ab-main-1 {
    grid-template-columns: 1fr;
    #about-pic {
      display: none;
    }
    #about-pic-2 {
      display: block;
      max-height: 400px;
      object-fit: contain;
    }
  }
}
@media (max-width: 380px) {
  #ab-main-1 {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
  #ab-main-2-mobile {
    #lebenslauf-table {
      .year {
        font-size: 0.75rem;
      }
      .desc {
        font-size: 0.75rem;
      }
    }
    h2 {
      font-size: 1rem;
    }
  }
}
</style>
